.loadingContainer {
  width: -moz-available;
  min-width: -moz-available;
  min-width: -webkit-fill-available;
  min-width: fill-available;
  height: 100%;
  top: 0;

  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  /* min-height: 597.4px; */
}
.fullLoadingContainer {
  width: -moz-available;
  min-width: -moz-available;
  min-width: -webkit-fill-available;
  min-width: fill-available;
  height: 100vh;
  top: -10px;
  left: 0;
  width: 100%;
  z-index: 800;

  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  /* min-height: 597.4px; */
}
.loadingContainerOverlay {
  min-width: -moz-available;
  width: -moz-available;
  min-width: -webkit-fill-available;
  min-width: fill-available;
  height: 100%;
  left: -10px;
  top: 0;
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  background-color: #000;
  opacity: 0.3;
  z-index: 800;
}
.loadingSpinner {
  z-index: 100;
}
.containerLoading {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.required {
  border-left: 3px solid #17a2b8;
  padding-left: 7px;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.infoIcon {
  color: #17a2b8;
}
.required.is-invalidText {
  border-left: 3px solid #dc3545 !important;
  padding-left: 7px;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
}
.errorMessage {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.error.richText {
  margin-top: -10px;
  margin-bottom: 16px;
}

.form-group.is-invalid {
  border: 1px solid #dc3545;
  /* padding-right: calc(1.5em + 0.75rem); */
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalidText {
  color: #dc3545;
}

.moduleFormImagePreview {
  height: auto;
  max-height: 100px;
  max-width: 100px;
}

.react-fancybox .thumbnail img {
  max-width: 100%;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.cmsModuleArrayListing .badge {
  margin-right: 5px;
}

.cmsModuleImageView div.thumbnail {
  max-width: 150px;
  max-height: 150px;
}

/* My Custom Styling */

.mydatatable {
}

.mydatatable .header {
}

.mydatatable .header .listing-count-options {
  display: "inline-block";
}

.mydatatable .footer {
  margin: 0 !important;
  padding: 0 !important;
}

/* .mydatatable .searchbox{
  float:right !important;
} */

/* .mydatatable .searchbox .input-group .input{
  width:200px;
} */

.mydatatable td.record-actions {
  width: 130px;
}

.mydatatable td.record-actions.MoreItem1 {
  width: 155px;
}

.mydatatable td.record-actions.MoreItem2 {
  width: 190px;
}

.mydatatable td.record-actions.MoreItem3 {
  width: 225px;
}

.mydatatable td.record-actions a {
  margin: 1px;
}

.mydatatable .serch-close {
  position: absolute;
  right: 40px;
  top: 3px;
  cursor: pointer;
}

.inDashboardErrorPage {
  margin: 0 auto;
  padding-top: 190px;
}

.resetFilters {
  color: orange;
  /* position: absolute;
  right: 0px;
  top: -27px;
  z-index: 100; */
  cursor: pointer;
  text-decoration: underline;
  /* font-style: italic; */
}

.mydatatable td.checkboxCell {
  width: 40px;
  text-align: center;
}

/* members */
.memberName {
  text-decoration: none;
  color: rgb(29, 64, 116);
}

.details {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}
.boardMemberText {
  background-color: #a2231d;
  color: #fff;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: "500";
  margin-left: 10px;
  position: absolute;
}
.details .headerText {
  background-color: #d6d6d6;
  width: 170px;
  border-radius: 0;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
.font-small {
  font-size: 14px;
}
.label {
  font-size: 13px;
}
.profile-subimage {
  left: 0px;
  top: -02px;
}
.icon-small {
  color: rgb(255, 255, 255);
  font-size: 10px;
  margin-left: 2px;
}
.success {
  background-color: green;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}
.reject {
  background-color: orangered;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}

.carouselimage {
  width: 150px;
  height: 150px;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0rem !important;
  margin-left: 15%;
  list-style: none;
}
.carousel {
  height: 350px;
}
.name {
  color: rgb(29, 64, 116);
}
.registrationCard {
  width: 250px;
  height: 100%;
}

@media (max-width: 600px) {
  .card-header > .card-tools {
    float: left !important;
    margin-right: -0.625rem;
    margin-top: 10px;
  }
  .carousel-image {
    height: auto;

    object-fit: cover;
    width: 100%;
  }

  .listingPage .listing .item .card-tools {
    margin-top: -5px;

    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer .col {
    margin-bottom: 10px;
  }

  .footer .text-right,
  .footer .text-center {
    display: flex;

    align-items: center;
    justify-content: flex-start;
  }
}
@media (min-width: 600px) {
  .carousel-image {
    height: auto !important;
    max-height: auto !important;
    object-fit: contain;
    width: 100%;
  }
  .carousel {
    height: auto !important;
  }
}

.statusIcon {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}

.memberStatusImage {
  width: 20px;
  margin-left: 10px;
}

.membershipId {
  margin-top: 25px;
}

.listingPage .paginationSummary {
  line-height: 32px;
  margin-right: 5px;
}

.listingPage .pagination {
  margin-bottom: 0;
}

.listingPage .listing .item .card-tools {
  margin-top: -5px;
}

.listingPage .listing .item .card-tools .listingActionIcon {
  margin: 0 2px;
  padding: 1px 6px;
}

.emailLink {
  cursor: pointer;
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.emailLink:hover {
  text-decoration: underline;
}

.ImageSectionSpacing {
  padding-top: 7px;
  padding-bottom: 5px;
}

.listingPage .listing .selectAll {
  margin-left: 4px;
}
/* .banned{
  position: absolute;
  font-size: 41px;
  color: red;
  left:-5px;
  top: -1px;
  opacity: 0.4;
} */
.banned {
  position: absolute;
  font-size: 45px;
  color: red;
  left: 0px;
  top: 0px;
  opacity: 0.4;
}
.banned2 {
  position: absolute;
  font-size: 45px;
  color: red;
  left: -7px;
  top: 0px;
  opacity: 0.4;
}
.banned3 {
  position: absolute;
  font-size: 38px;
  color: red;
  left: -7px;
  top: 0px;
  opacity: 0.4;
}
.banned4 {
  position: absolute;
  font-size: 38px;
  color: red;
  left: -3px;
  top: 0px;
  opacity: 0.4;
}
.usericon {
  font-size: 36px;
  color: gray;
}
.cartIcon {
  font-size: 28px;
  color: rgb(77, 77, 77);
}
.buyerIcon {
  font-size: 35px;
  color: rgb(77, 77, 77);
  margin-bottom: 5px;
}
.cursor-move {
  cursor: move;
}
.dragableContent {
  cursor: move;
  border: 1px dashed gray;
  background-color: #eee;
}
.listStyle {
  list-style-type: none;

  cursor: move;
}

.carImage {
  max-height: 250px;
}
.carImage img {
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 250px;
}
.accordion-button {
  padding: 8px 8px;
}
.pageContent .react-fancybox .thumbnail {
  width: 150px;
  min-height: 150px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContent .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
}
.pageContent .react-fancybox {
  width: 150px;
  min-height: 150px;
  margin-bottom: 20px;
}
.pageContent .thumbnail2 {
  width: 150px;
  min-height: 150px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContent .thumbnail2 img {
  width: 100%;
  height: auto;
}
.pageContent {
  width: 150px;
  min-height: 150px;
  margin-bottom: 20px;
}
.pageContent .iconDiv {
  background-color: #ccc;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 130px;
  top: -5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: 100;
}

.formImagePreview {
  max-width: 200px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.imagePreview {
  display: flex;
  flex-direction: row;
}

.croppingArea {
  position: relative;
  width: 300px;
  height: 200px;
  background: rgb(167, 163, 163);
  margin-bottom: 10px;
}
.imageThumbArea {
  display: flex;
  flex-direction: row;
}

.imageActionsContainer {
  width: auto;
  /* height:50px; */
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 190px;
}
.imageActionsContainerBack {
  width: 100%;
  /* height:50px; */
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 280px;
}

.imageAction {
  width: 30px;
  height: 30px !important;
  margin: 0px;
  padding: 0px;
  margin-top: -4px;
  margin-bottom: 35px;
  background: rgb(221, 220, 220);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageAction i {
  font-size: 20px;
}

.moduleHeader h4 {
  padding-top: 5px;
  margin: 0px;
}

/* gallery */

.roundButton {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.galleryImage {
  max-width: 200px;
}
.fossilImage {
  width: 40px;
  height: 40px;
}

.fossilImage .react-fancybox .thumbnail img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.fossilImage .react-fancybox {
  max-width: 40px;
}
.galleryImageBg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.galleryImageBgCol {
  max-width: 250px;
}
.galleryImageBg .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-width: 250px;

  object-fit: cover;
}
.galleryImageBg .react-fancybox {
  max-width: 250px;
}
.galleryImageVideo {
  width: 100%;
  height: 200px;

  object-fit: cover;
}
.galleryImageFull {
  width: 100%;
}
.galleryImageFull .holdsTheIframe iframe {
  width: 100%;
  height: auto;
  min-height: 200px;
  object-fit: cover;
}
.galleryImageFull .holdsTheIframe iframe {
  width: 100%;
  height: auto;
  min-height: 200px;
  object-fit: cover;
}
.galleryImageFull .holdsTheIframe {
  max-width: 100%;
  height: 200px;
}

.galleryImageFull .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}
.galleryImageFull .react-fancybox {
  max-width: 100%;
}
.galleryImage .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-width: 200px;
}
.galleryImage .react-fancybox {
  max-width: 200px;
  margin-bottom: 20px;
}
.newsImageCol {
  max-width: 200px;
  width: 200px !important;
}
.newsImage {
  max-width: 200px;
}
.newsImage .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-width: 200px;
}
.newsImage .react-fancybox {
  max-width: 200px;
  margin-bottom: 5px;
}
.testimonialImage {
  max-width: 150px;
}
.testimonialImage .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-width: 150px;
}
.testimonialImage .react-fancybox {
  max-width: 150px;
  margin-bottom: 20px;
}
.custom-file-label.is-invalid {
  border: 1px solid #dc3545 !important;
}
.custom-file-label.is-invalid::after {
  color: #dc3545 !important;
}
#is-invalidInputGroupFileAddon01 {
  border: 1px solid #dc3545 !important;
  color: #dc3545;
}

.pictureTitle {
  font-size: 18px;
  font-weight: 600;
}
.uploadedBy {
  font-size: 18px;
  font-weight: normal;
  /* font-style: italic; */
  color: #444;
  margin-top: 3px;
}
/* .photoInsideAlbum {
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* text-editor */

.rdw-editor-toolbar {
  border: 1px solid #ccc !important;
}

/* comment */
.commentContainer {
  display: flex;
}
.recent-comments .direct-chat-info .right > .direct-chat-text::after,
.direct-chat-info .right > .direct-chat-text::before {
  border-left-color: transparent;
}
.recent-comments .direct-chat-info .right > .direct-chat-text {
  background-color: #e3e7ed;
  border-color: #e3e7ed;
}
/* likes */
.recent-likes .direct-chat-info .right > .direct-chat-text::after,
.direct-chat-info .right > .direct-chat-text::before {
  border-left-color: transparent;
}
.recent-likes .direct-chat-info .right > .direct-chat-text {
  background-color: #e3e7ed;
  border-color: #e3e7ed;
}
.chatTime {
  font-style: italic;
  font-size: 12px;
}
.memberMessage {
  background-color: #dbd1ff;
  border-color: #dbd1ff;
}
.memberMessage.direct-chat-text::before {
  border-right-color: #dbd1ff !important;
}
.memberMessage.direct-chat-text::after {
  border-right-color: #dbd1ff !important;
}
/* circle */
.memberRemove {
  height: 20px;
}
.emailDisplay .emailLink {
  color: #000 !important;
}
.searchWrapper {
  border: 0px;
  margin-top: -10px;
}
.likesCommentsLinks {
  width: 100%;
}
label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400 !important;
}
.rdw-link-modal-input {
  border: 1px solid rgb(113, 112, 112);
}

.rdw-link-modal {
  min-height: 255px;
}

.DraftEditor-root {
  z-index: 0;
}
.customDatePicker {
  width: 100%;
}
.optionListContainer.displayBlock {
  left: 0;
}
.input-group {
  z-index: 0;
}
.limeYellow {
  background-color: #8d9e20;
}
.orange {
  background-color: #d19628;
}
.peach {
  background-color: #ff7f50;
}
.brown {
  background-color: #80403d;
}
.magenda {
  background-color: #de3163;
}
.lightGreen {
  background-color: #9fe2bf;
}
.skyBlue {
  background-color: #5ca59e;
}
.lightBlue {
  background-color: #6495ed;
}
.PaleBlue {
  background-color: #8181aa;
}

.homePageShow {
  background-color: #ff389b;
}
.red {
  background-color: #ee3b1c;
}
.teal {
  background-color: rgb(114, 7, 201);
}
.purple {
  background-color: rgb(140, 7, 201);
}
.greenish {
  background-color: rgb(102, 131, 49);
}
.rdw-editor-wrapper {
  margin-bottom: 15px;
}
.form-group.is-invalid .rdw-editor-wrapper {
  margin-bottom: 0px;
}
.accordion-item {
  margin-bottom: 10px;
}
.accordionBorder {
  border-top: 2px solid #ecc57e;
}
.accordionBorderMember {
  border-top: 2px solid rgb(40, 95, 15);
}

.messageItem {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-top: 2px solid rgb(163, 163, 163);
}

.paymentPending {
  background-color: #ffa011;
}
.resubmission {
  letter-spacing: 1px;
}
.form-control.select {
  appearance: auto !important;
}
.form-control.is-invalid.select {
  appearance: none !important;
}
.hoveredText {
  color: #000;
}
.hoveredText:hover {
  color: navy;
}
.text-normal {
  font-weight: normal;
}
.multiSelectContainer {
  height: auto !important;
}
.warningDark {
  background-color: rgba(255, 150, 17, 0.6);
}
.dashboardConflictContainer {
  background-color: #ddd;
  border-radius: 5px;
}
.dashboardCarDetails {
  display: flex !important;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
}
@media (max-width: 500px) {
  .separation {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 10px;
  }
  .newsImageCol {
    max-width: 100%;
    width: 100% !important;
  }
  .dashboardCart {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: space-between;
  }
  .endColumn {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start;
    flex-direction: column;
  }
  .error-content {
    text-align: center;
  }
  .react-fancybox .close-btn {
    background-color: white;
    width: 25px !important;
    border-radius: 50%;
    height: 25px;
    position: absolute;
    right: -13px;
    top: -13px;
  }
  .react-fancybox .box .image-box {
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    width: 92%;
  }
  .react-fancybox .box .image-box img {
    width: 100%;
  }
  .galleryImageFull {
    width: 100% !important;
  }
  .photoInsideAlbum {
    display: block;
  }
  .subscriberTopSection {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
@media (min-width: 1360px) {
  .dashboardCarDetails {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
  }
  .separation {
    border-left: 1px solid #ccc;
  }
}
.displayEmails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-height: 100px;
  overflow-y: scroll;
}
.displayEmails .emailItem {
  width: fit-content;
  height: 25px;
  background-color: #ddd;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: #000;
}
.displayEmails .emailItem .emailRemoveIcon {
  color: rgb(0, 0, 0);
  font-size: 12px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.dropButton {
  background-color: #007bff;
  border-radius: 3px;
  height: 37px;
}
.dropButton a {
  color: #fff;
}
.dropButton a:hover {
  color: #fff;
}
.dropButton a:active {
  color: #fff;
}
.dropButton a:focus {
  color: #fff;
}
.messageWidth {
  max-width: fit-content;
}
.endColumn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.lightGray {
  background-color: rgb(245, 245, 245);
}
.lightCream {
  background-color: rgb(255, 249, 237);
}
.galleryImageCard {
  max-width: fit-content;
}
.galleryImageCol {
  max-width: 250px;
}
.ribbon-wrapper {
  height: 70px;
  overflow: hidden;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 70px;
  z-index: 0 !important;
}
.smallImage {
  width: 100%;
  max-width: 100px;

  height: 80px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smallImagePosition {
  width: 100%;
  max-width: 100px;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.info-box .info-box-content {
  line-height: 1.5 !important;
}
.text-red {
  color: #992926 !important;
}
.text-blue {
  color: #29b4b8;
}
.text-boldItalic {
  font-weight: bold;
  font-style: italic;
}
.text-primaryCream {
  color: #b88528 !important;
}
.borderCream {
  border: 1px solid #ecc57e !important;
}
.borderOrange {
  border: 1px solid #fda80a !important;
}
.borderGreen {
  border: 1px solid #33ce2e !important;
}
.bgCream {
  background-color: rgba(245, 222, 177, 0.9) !important;
  color: #992926 !important;
}
.bgOrange {
  background-color: #992926 !important;
  color: #fff !important;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #fff8eb;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:focus {
  z-index: 3;
  /* border-color: #86b7fe; */
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 255, 255, 1);
}
.dashboardCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cursor-normal {
  cursor: default;
}
.react-fancybox .box {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999 !important;
}
.orderImage {
  width: 40px;
  height: 40px;
}
.fossilImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.adminCommentsHead {
  font-size: 14px;
  font-weight: bold;
}
.adminComments {
  font-size: 14px;
  font-style: italic;
}
.buyerFlex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.videoModal .modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.videoModal .modal-body {
  position: relative;
  padding: 0px;
}
.closeVideo {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999;
  font-size: 2rem;
  border-radius: 50%;
  font-weight: normal;
  color: #000;
  background-color: #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoThumbnail {
  max-width: 320px;
}
.BuyerImage {
  position: relative;
  width: 100%;
  height: 35px;
}
.BuyerImage .image {
  position: absolute;
  left: 0;
  right: 0;
  top: -28px;
  margin: auto;
  background-color: #ccc;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-box > .small-box-footer:hover {
  background-color: rgba(245, 222, 177, 0.9) !important;
  color: #992926 !important;
}
.small-box > .small-box-footer {
  background-color: rgba(245, 222, 177, 0.4) !important;
  color: #992926;
}
.small-box .icon {
  color: rgba(206, 198, 182, 0.9) !important;
  z-index: 0;
}
.small-box .icon .green {
  color: rgba(77, 168, 96, 0.9) !important;
  z-index: 0;
}
.soldOut {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.soldOut:hover {
  background-color: transparent;
  z-index: -99 !important;
}
.soldOutText {
  color: #fff;
  font-style: italic;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}
.subscriberTopSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.smallInfo {
  font-size: 14px;
  color: #444;
}
.dropdown-item {
  cursor: pointer;
}
.mot {
  background-color: #ecc57e !important;
  border: 1px solid #ecc57e !important;
  color: #992926 !important;
}
.motLight {
  background-color: #f2d69c !important;
  border: 1px solid #f2d69c !important;
  color: #992926 !important;
}
.motDark {
  background-color: #992926;
  border: 1px solid #992926;
  color: #ecc57e;
}
.motText {
  color: #992926;
}
/* .motLightText {
  color: #ecc57e !important;
} */
.mot:hover {
  background-color: #ecc57e;
  border: 1px solid #ecc57e;
  color: #992926;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-tabs .nav-link {
  color: #992926 !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #f2d69c !important;
}
.accordion-button:not(.collapsed) {
  color: #992926 !important;
}
.accordion-button {
  font-size: 1rem;
  color: #992926 !important;
  text-align: left;
}
.moduleHeader h4 {
  color: #000 !important;
  font-weight: bold;
  text-transform: capitalize;
}
.form-select:focus {
  border: 1px solid #f2d69c !important;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(245, 222, 177, 0.25);
}
.form-select,
.moduleSearch {
  border: 1px solid #f2d69c !important;
  background-color: rgba(245, 222, 177, 0.25);
  color: #992926;
}
.moduleSearch::placeholder {
  color: #992926;
}
.form-select:disabled {
  background-color: #e9ecef;
  border: 1px solid #e9ecef !important;
  color: #444;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  background-color: rgba(245, 222, 177, 0.15);

  --bs-table-accent-bg: none;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
  border-color: #f2d69c;
}
.page-item.active .page-link {
  z-index: 3;
  color: #992926;
  background-color: #f2d69c;
  border-color: #f2d69c;
}
.page-link:hover {
  z-index: 2;
  color: #992926;
  text-decoration: none;
  background-color: rgba(245, 222, 177, 0.25);
  border-color: rgba(245, 222, 177, 0.25);
}
.page-link {
  z-index: 2;
  color: #992926;
  text-decoration: none;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #992926;
  background-color: #f2d69c;
}
.nav-pills .nav-link:not(.active):hover {
  color: #992926;
}

.intl-tel-input {
  position: relative;
  display: inline-block;
  width: 100%;
}
.nav-link {
  cursor: pointer;
}
.boldEmail {
  font-weight: bold !important;
  color: #992926;
}
.spinner-border {
  z-index: 1!important;
}